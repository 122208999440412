/*
 * @Author: jason
 * @Date: 2024-10-24 16:33:18
 * @LastEditTime: 2024-12-14 15:59:10
 * @LastEditors: jason
 * @Description:
 * @FilePath: \lonsdor-center-backend\src\store\modules\fonticon.ts
 *
 */
import { defineStore } from "pinia";
import { store } from "../utils";
import axios from "axios";

export type glyphsItem = {
  font_class: string;
  icon_id: string;
  name: string;
  unicode: string;
  unicode_decimal: string;
};

export type IconDataType = {
  css_prefix_text: string;
  description: string;
  font_family: string;
  id: string;
  name: string;
  glyphs: glyphsItem[];
};

type StateType = {
  appIconData: IconDataType;
  brandIconData: IconDataType;
};

export const useFonticonStore = defineStore({
  id: "fonticon",
  state: (): StateType => ({
    // 应用图标
    appIconData: {
      css_prefix_text: "",
      description: "",
      font_family: "",
      id: "",
      name: "",
      glyphs: []
    },
    brandIconData: {
      css_prefix_text: "",
      description: "",
      font_family: "",
      id: "",
      name: "",
      glyphs: []
    }
  }),
  actions: {
    /** 获取app图标 */
    async getAppIconFn() {
      let res = await axios.get(
        "https://at.alicdn.com/t/c/font_4555801_irobanhapff.json?spm=a313x.manage_type_myprojects.i1.6.38b33a81RaDTjG&file=font_4555801_irobanhapff.json"
      );

      if (res.data) {
        this.appIconData = res.data;
      }
    },

    /** 获取汽车品牌图标 */
    async getBrandIconFn() {
      let res = await axios.get(
        "https://at.alicdn.com/t/c/font_4753041_rspp7o7r26b.json?spm=a313x.manage_type_myprojects.i1.6.6f5f3a816i0hQ2&file=font_4753041_rspp7o7r26b.json"
      );
      if (res.data) {
        this.brandIconData = res.data;
      }
    }
  }
});

export function useFonticonStoreHook() {
  return useFonticonStore(store);
}
